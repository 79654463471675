import { useState, useEffect } from "react";

let recognition = null;
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

if (SpeechRecognition) {
    recognition = new SpeechRecognition();
    recognition.continuous = false; // Changed to false to prevent multiple recordings
    recognition.lang = "en-US";
}

const useSpeechRecognition = () => {
    const [text, setText] = useState("");
    const [isListening, setIsListening] = useState(false);

    useEffect(() => {
        if (!recognition) return;

        recognition.onstart = () => {
            setIsListening(true);
            console.log("Started listening...");
        };

        recognition.onend = () => {
            setIsListening(false);
            console.log("Stopped listening.");
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setText(transcript);
            console.log("Transcript:", transcript);
        };
    }, []);

    const startListening = () => {
        setText('');
        try {
            recognition.start();
        } catch (error) {
            console.error("Error starting recognition:", error);
        }
    };

    const stopListening = () => {
        try {
            recognition.stop();
        } catch (error) {
            console.error("Error stopping recognition:", error);
        }
    };

    return {
        startListening,
        stopListening,
        hasRecognitionSupport: !!recognition,
        text,
        isListening
    };
};

export default useSpeechRecognition;