import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useSpeechRecognition from './hooks/useSpeechRecognition';
import useSpeech from './hooks/useSpeech';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const { text, startListening, hasRecognitionSupport, isListening } = useSpeechRecognition();
  const { speakText, voices } = useSpeech();
  const [interactionMode, setInteractionMode] = useState(null);
  useEffect(() => {
    if (text && !isListening) {
        handleSpeechInput(text);
    }
}, [text, isListening]);

const fetchPlumbingServiceInfo = async (service) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/plumbing-service-info`, { service });
        console.log('Plumbing Service Info:', response.data);  // Log to see the fetched service info
        await speakText(`Here's the information for ${service}: ${response.data.details.join(', ')}`, selectedVoice);
    } catch (error) {
        console.error('Error fetching plumbing service info:', error);
        await speakText('Sorry, there was an error fetching the plumbing service information.', selectedVoice);
    }
};
//hio
const handleSpeechInput = async (text) => {
    setIsLoading(true);
    try {
      if (interactionMode === 'plumber') {
        // Extract service name from speech input
        const serviceKeywords = {
          'pipe inspection': 'pipeInspection',
          'drain cleaning': 'drainCleaning',
          'burst pipe': 'burstPipe',
          'clogged drain': 'cloggedDrain',
          'water heater': 'waterHeater',
          'toilet installation': 'toiletInstallation'
        };
  
        // Find matching service
        const service = Object.entries(serviceKeywords).find(([keyword]) => 
          text.toLowerCase().includes(keyword)
        )?.[1];
  
        if (service) {
          await fetchPlumbingServiceInfo(service);
        } else {
          await speakText('I couldn\'t identify the plumbing service you\'re asking about. Please try again with a specific service name.', selectedVoice);
        }
      } else {
        // Handle AI chat
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/chat`, { text });
        if (response.data) {
          await speakText(response.data, selectedVoice);
        }
      }
    } catch (error) {
      console.error('Error communicating with server:', error);
      await speakText('Sorry, there was an error processing your request.', selectedVoice);
    } finally {
      setIsLoading(false);
    }
  };

return (
    <div className="min-h-screen bg-gray-100 p-4">
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6">
            <h1 className="text-2xl font-bold mb-4">Voice Assistant</h1>

            {!hasRecognitionSupport ? (
                <div className="text-red-500">
                    Speech recognition is not supported in this browser.
                </div>
            ) : (
                <div className="space-y-4">
                    <select
                        className="w-full p-2 border rounded-lg"
                        onChange={(e) => setSelectedVoice(voices[e.target.value])}
                    >
                        <option value="">Select Voice</option>
                        {voices.map((voice, index) => (
                            <option key={index} value={index}>
                                {voice.name} ({voice.lang}) {voice.default ? "(Default)" : ""}
                            </option>
                        ))}
                    </select>

                    <div className="flex space-x-4">
                        <button
                            className={`w-full p-4 rounded-full ${
                                interactionMode === 'ai' ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'
                            } text-white font-medium transition-colors`}
                            onClick={() => setInteractionMode('ai')}
                        >
                            Chat with AI
                        </button>

                        <button
                            className={`w-full p-4 rounded-full ${
                                interactionMode === 'plumber' ? 'bg-orange-500 hover:bg-orange-600' : 'bg-blue-500 hover:bg-blue-600'
                            } text-white font-medium transition-colors`}
                            onClick={() => setInteractionMode('plumber')}
                        >
                            Plumber Assistant
                        </button>
                    </div>

                    <button
                        className={`w-full p-4 rounded-full ${
                            isListening
                                ? 'bg-red-500 hover:bg-red-600'
                                : 'bg-blue-500 hover:bg-blue-600'
                        } text-white font-medium transition-colors ${
                            isLoading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        onClick={startListening}
                        disabled={isLoading || isListening}
                    >
                        {isListening ? 'Listening...' : 'Start Speaking'}
                    </button>

                    {text && (
                        <div className="p-4 bg-gray-50 rounded-lg">
                            <p className="font-medium">You said:</p>
                            <p className="text-gray-700">{text}</p>
                        </div>
                    )}

                    {isLoading && (
                        <div className="flex items-center justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                            <span className="ml-2">Processing...</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    </div>
);
};

export default App;
