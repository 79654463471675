import { useEffect, useState } from "react";

const useSpeech = () => {
    const [voices, setVoices] = useState([]);

    useEffect(() => {
        const updateVoices = () => {
            const availableVoices = window.speechSynthesis.getVoices();
            setVoices(availableVoices);
        };

        // Update voices when they are loaded
        window.speechSynthesis.onvoiceschanged = updateVoices;
        updateVoices();
    }, []);

    const speakText = (text, selectedVoice = null) => {
        return new Promise((resolve, reject) => {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = 'en-US';

            // If a specific voice is provided, set it
            if (selectedVoice) {
                utterance.voice = selectedVoice;
            }

            utterance.onend = () => resolve();
            utterance.onerror = (error) => reject(error);

            window.speechSynthesis.speak(utterance);
        });
    };

    return { speakText, voices };
};

export default useSpeech;
